

.closeButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
  margin: 30px auto 5px auto;
  font-size: 14.5px;
  background-color: #FF6315;

}

.headerContainer {
  height: 40px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 40px;
}
.lastUpdate {
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #a9a9a9;
}

.softwareContainer {
  display: flex;
  flex-flow: row;
  margin: 0 -12px;
}
.softwareColumn {
  flex: 1 1 100px;
  margin: 0px 15px;
}
