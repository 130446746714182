

h2 {
  margin-bottom: 30px;
}

.buttonRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 40px 0 5px 0;
}
.closeButton, .deleteButton, .saveButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
  font-size: 14.5px;
  margin: 0 8px;
}

.saveButton {
  background-color: #FF6315;
}
.closeButton {
  background-color: rgb(192, 192, 192);
}
.deleteButton {
  background-color: #e74c3c;
}