

.closeButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
  margin: 30px auto 5px auto;
  font-size: 14.5px;
  background-color: #FF6315;

}
