
.rowContainer {
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E5E7ED;
}

.rowContainer.isFirstRow {
  border-top: none;
}

.rowContainer p {
  padding: 0px 5px;
  flex: 1 1 auto;
}


.title {
  font-weight: 600;
  text-align: left;
  font-size: 15px;
}


.inputField {
  margin: auto;
  padding: 0 0 0 5px;
  height: 30px;
  width: 173px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid hsl(0,0%,80%);
}
.extendedWidth {
  width: 223px;
}
.ultrawide {
  width: 55%;
}
.inputField:disabled {
  color: darkgray;
  border: none;
}
.inputField:focus {
  outline: none;
}
.invalid {
  border-color: red;
  border-width: 1.5px;
}
