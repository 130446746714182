


.subtitle {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 30px;
}


/* Titles */

.highlightContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 0 0 50px 0;
}

.highlightTile {
    width: 23%;
    margin: 0 20px;
    height: 130px;
    background-color: rgb(244,244,245);
    border-radius: 10px;
    box-shadow: 0px 0px 25px #b8b8b8;

    display: flex;
    flex-flow: column;
    justify-content: center;
}

.highlightTile label:nth-child(1) {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 33px;
    font-weight: 900;
}
.highlightTile label:nth-child(2) {
    font-size: 15.5px;
    color: #86868b;
    font-weight: 700;
    text-align: center;
}


/* Progress bars */

.progressContainer {
    width: 85%;
    margin: 40px auto 0 auto;
}
.progressContainer > div {
    margin-bottom: 25px;
}