

h3 {
  margin-bottom: 40px;
}


.buttonRow {
  width: 100%;
  margin: 60px 0 10px 0;

  display: flex;
  flex-flow: row;
  justify-content: center;
}

.generateButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 100px;
  font-size: 14.5px;
  background-color: #FF6315;
  margin-left: 15px;
}
.cancelButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 70px;
  font-size: 14.5px;
  background-color: lightgray;
}
