

.container {
  background-color: #252D36;
  width: 280px;
  top: 0;
  left: 0;
  position: fixed;
  margin-top: 65px;
  overflow-y: scroll;
  overflow-x: hidden;

  height: -webkit-calc(100% - 65px);
  height:    -moz-calc(100% - 65px);
  height:         calc(100% - 65px);

  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.subcontainer {
  margin-top: 15px;
  margin-bottom: 25px;
}

.versionNumber {
  color: darkgray;
  font-weight: 600;
  width: 280px;
  font-size: 13.5px;
  text-align: center;
}
