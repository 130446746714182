
.rowContainer {
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E5E7ED;
}

.rowContainer.isFirstRow {
  border-top: none;
}

.rowContainer p {
  padding: 0px 5px;
  flex: 1 1 auto;
}


.title {
  font-weight: 600;
  text-align: left;
  font-size: 15px;
}
.body {
  flex: 1 1 auto;
  text-align: right;
  color: darkgray;
  font-size: 14px;
}
