
.addButton {
  width: 110px;
  height: 30px;
  font-size: 14px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 5px 15px auto;
  float: right;
}
