


/* Platform selection */

.tileRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 30px;
}

.stockTile, .statTile {
  flex: 1 1 100px;
  height: 130px;
  margin: 0 20px;
  max-width: 210px;

  background-color: rgb(244,244,245);
  border-radius: 10px;
  box-shadow: 0px 0px 25px #b8b8b8;
  border: 3px solid rgb(244,244,245);

  display: flex;
  flex-flow: column;
}


/* Stock tile */

.stockTile {
  display: flex;
  flex-flow: row;
}

.tileImage {
  flex: 1 1 100px;
  margin: 12px 10px;
}
.tileImage img {
  object-fit: contain;
  height: 100%;
  width: 80%;
}

.rightContainer {
  flex: 1 1 100px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-right: 12px;
}

.tileTitle {
  font-size: 18px;
  color: #69696c;
  font-weight: 800;
  text-align: center;
  margin-bottom: 15px;
}

.tileContent {
  text-align: center;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: 900;
  color: #4e4e51;
  margin-top: 25px;;
}

/* Stat tile */

.statTile {
  flex: 1 1 100px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.statTile .tileContent {
  font-size: 30px;
}



/* Filter button */

.filterRow {
  margin-top: 5px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.filtered, .unfiltered {
  margin-left: auto;
  margin-right: 20px;
  margin-top: auto;
  height: 30px;

  cursor: pointer;
  border: none;
  background-color: white;
  font-size: 15px;
  line-height: 20px;
  font-family: Helvetica;

}
.filtered {
  font-weight: bold;
  color: #FF6315;
}
.filtered:hover, .unfiltered:hover {
  color: #A9A9A9;
}
.filterIcon {
  height: 12px;
  width: 20px;
  margin-left: 4px;
}



/* Build list */

.tableContainer {
  margin-top: 45px;
}

.stockIndicator {
  padding: 0 15px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  margin-top: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
