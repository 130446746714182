
.titleContainer {
  height: 40px;
  margin-top: -12px;
  margin-bottom: 20px;
}

.titleContainer p {
  font-size: 22px;
  line-height: 40px;
  width: 100%;
}



.buttonContainer {
  display: block;
  width: 280px;
  height: 30px;
  margin: 45px auto 5px auto;
}

.cancelButton, .exportButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  float: left;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
}

.cancelButton {
  background-color: lightgray;
  margin-right: 10px;
  font-size: 14.5px;
}
.exportButton {
  background-color: #FF6315;
  margin-left: 10px;
  font-size: 15.5px;
}
.cancelButton:hover, .exportButton:hover {
  color: #F2F2F2;
}
