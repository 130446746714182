

/* Upload row */
.uploadRow {
  display: flex;
  justify-content: right;
}
.uploadRow label {
  width: 90px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}
.uploadRow input {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}


/* Platform selection */

.platformRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-top: 30px;
}

.platformTile {
  width: 200px;
  height: 200px;
  margin: 0 30px;

  background-color: rgb(244,244,245);
  border-radius: 10px;
  box-shadow: 0px 0px 25px #b8b8b8;
  border: 3px solid rgb(244,244,245);

  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.platformTile.selected {
  border: 3px solid #FF6315;
}


.platformImage {
  margin-top: 20px;
  height: 65%;
  width: 100%;
}
.platformImage img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.platformName {
  font-size: 18px;
  color: #69696c;
  font-weight: 800;
  text-align: center;
  margin-bottom: 12px;
}


/* Build list */

.tableContainer {
  margin-top: 45px;
}

.statusIndicator {
  padding: 0 12px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  margin-top: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}