
.container {
  height: 100%;
  display: flex;
  flex-flow: row;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
}


/* Properties table */

.propertiesTable {
  flex: 2 1 100px;
  border-collapse: collapse;
  margin-left: 35px;
  margin-bottom: auto;
}

.tableLabel {
  font-size: 15px;
  font-weight: bold;
  min-width: 100px;
  display: inline-block;
}
.tableValue {
  font-size: 15px;
  color: #34495e;
  float: left;
  word-break: break-all;
}

.propertiesTable td {
  padding: 6px;
  font-size: 15px;
}


/* Features list */

.featureContainer {
  flex: 2 1 100px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 20px;
}
.featureContainer p {
  margin: 5px 0 0 0;
  font-weight: bold;
  font-size: 15px;
}
.featureContainer ul {
  margin-top: 10px;
  padding-left: 20px;
  margin-bottom: auto;
}
.featureContainer li {
  font-size: 15px;
  padding: 3.5px 0;
}


/* Actions */

.actionContainer {
  flex: 1 1 100px;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding-right: 10px;
}
.actionContainer button {
    flex: 1 1 auto;
    text-align: right;
    color: #0275d8;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: auto;
    margin-top: 5px;
}

.actionContainer button:hover {
  color: #f26c4f;
}
