
.rowContainer {
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E5E7ED;
}

.rowContainer.isFirstRow {
  border-top: none;
}

.rowContainer p {
  padding: 0px 5px;
  flex: 1 1 auto;
}


.title {
  font-weight: 600;
  text-align: left;
  font-size: 15px;
}

.fileInput {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.fileLabel {
  flex: 1 1 auto;
  text-align: right;
  color: #0275d8;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 5px;
}
.fileLabel:hover {
  color: #f26c4f;
}
