.content {
  margin-top: 65px;
  margin-left: 280px;
  position: fixed;

  height: -webkit-calc(100% - 65px);
  height:    -moz-calc(100% - 65px);
  height:         calc(100% - 65px);

  width: -webkit-calc(100% - 280px);
  width:    -moz-calc(100% - 280px);
  width:         calc(100% - 280px);

  display: flex;
  flex-flow: column;
}
