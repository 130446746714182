


.buttonRow {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 20px 0 5px 0
}
.closeButton, .deleteButton {
  color: white;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  display: block;
  height: 30px;
  width: 130px;
  font-size: 14.5px;
  margin: 0 8px;
}

.closeButton {
  background-color: #FF6315;
}
.deleteButton {
  background-color: #e74c3c;
}


.licenseField {
  width: 100%;
  height: 45px;
  border: 1px solid lightgrey;
  font-size: 10px;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 25px 0px 0px;
  resize: none;
}
