
.addButton {
  width: 120px;
  height: 30px;
  font-size: 14px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 5px 15px auto;
  float: right;
}


/* Stats */

.highlightContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.highlightTile {
  width: 20%;
  margin: 0 20px;
  height: 120px;
  background-color: rgb(244,244,245);
  border-radius: 10px;
  box-shadow: 0px 0px 25px #b8b8b8;

  display: flex;
  flex-flow: column;
  justify-content: center;
}

.highlightTile label:nth-child(1) {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 33px;
  font-weight: 900;
}
.highlightTile label:nth-child(2) {
  font-size: 15.5px;
  color: #86868b;
  font-weight: 700;
  text-align: center;
}
