
/* Create button */

.buttonRow {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}

.addButton {
  width: 150px;
  height: 30px;
  font-size: 15px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 10px 15px 0px;
}


/* Filter button */

.filtered, .unfiltered {
  
  margin-left: auto;
  margin-right: 20px;
  padding-top: 7px;;
  height: 30px;
  cursor: pointer;
  border: none;
  background-color: white;
  font-size: 15px;
  line-height: 20px;
  font-family: Helvetica;
  margin-top: auto;
  margin-bottom: 20px;
}
.filtered {
  font-weight: bold;
  color: #FF6315;
}
.filtered:hover, .unfiltered:hover {
  color: #A9A9A9;
}
.filterIcon {
  height: 12px;
  width: 20px;
  margin-left: 4px;
}


/* Stock result indicator */

.resultIndicator {
  text-align: center;
  padding: 0 12px;
  height: 26px;
  line-height: 26px;
  border-radius: 4px;
  margin-top: 2px;
  font-size: 15.5px;
  font-weight: bold;
  color: white;
}