

.title {
  font-size: 27px;
  padding: 30px 30px 18px 30px;
  margin: 0;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.container {
  padding: 20px 35px;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: auto;
}

.hr {
    width: 94%;
    border-top: 1px solid #E3E1E1;
    margin: 0 auto;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
}
