
.subtitle {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 30px;
}

.highlightContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 0 0 50px 0;
}

.highlightTile {
    width: 23%;
    margin: 0 20px;
    height: 130px;
    background-color: rgb(244,244,245);
    border-radius: 10px;
    box-shadow: 0px 0px 25px #b8b8b8;

    display: flex;
    flex-flow: column;
    justify-content: center;
}

.highlightTile label:nth-child(1) {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 33px;
    font-weight: 900;
}
.highlightTile label:nth-child(2) {
    font-size: 15.5px;
    color: #86868b;
    font-weight: 700;
    text-align: center;
}



/* Omzet per groep */

.groupContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 50px;
}
.groupDialog {
    width: 50%;
}
.tableContainer {
    margin: 0 50px;
}


/* Resultaten */

.overviewTable {
    border-collapse: collapse;
    margin: 30px 0 30px 20px;
}
  
.overviewTable td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
}
.overviewTable td:nth-child(1) {
    width: 210px;
    background-color: rgb(234, 234, 234);
    border-color: rgb(182, 182, 182);
}
.overviewTable td:nth-child(2) {
    width: 200px;
    height: 18px;
    font-size: 15px;
}

.overviewTable input {
    margin: 0;
    padding: 0 0 0 5px;
    width: 98%;
    height: 100%;
    border: none;
    outline: none;
}
.overviewTable label {
    padding-left: 5px;
}


