
.label {
  width: 100%;
  padding: 2px 0 !important;
  font-size: 18px;
  font-weight: 400;
  color: white;
  font: inherit;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.label:hover {
  color: #A9A9A9;
}


.toBeImplemented {
  font-weight: normal;
  color: #A9A9A9;
  font-size: 14px;
  font-style: italic;
}

.selected,
.selected.label:hover {
  color: #FF6315;
}
