


.container {
  display: flex;
  flex-flow: row;
}
.title {
  flex: 0 0 200px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  font-size: 17px;
}
.barContainer {
  flex: 10 0 200px;
}



.progressBar {
  position: relative;
  height: 30px;
  background: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background: linear-gradient(90deg, #e09a77, #ff6315);
  transition: width 0.5s ease-in-out;
}
.percentage {
  position: absolute;
  top: 7px;
  left: 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

/* Labels */
.markerLabels {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
  /* margin: 0 10px; */
  color: #86868b;
  font-weight: bold;
}
.markerLabels div {
  text-align: center;
  font-size: 12px;
  position: relative;
}
.highlight {
  font-weight: bold;
  color: #ff6315;
}

/* Lines */
.markerLines {
  display: flex;
  justify-content: space-between;
}
.line {
  background-color: #9c9c9f;
  width: 1.5px;
  height: 8px;
}

