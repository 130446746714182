
/* Protocol select row */

.rowContainer {
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E5E7ED;
}

.rowContainer p {
  padding: 0px 5px;
  flex: 3 1 10%;
}

.rowTitle {
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  flex: 1 1 auto;
}

.boxTable {
  flex: 5 1 10%;
  border-collapse: collapse;
  font-size: 15px;
  table-layout: fixed;
  margin-top: 10px;
}
.boxTable tr {
  display: flex;
  flex-flow: row;
  height: 25px;
}
.boxTable td:nth-child(even) {
  padding: 0 0 0 5px;
  line-height: 26px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkbox {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 5.5px; 
  margin-left: 15px;
}