

.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row;
  margin-bottom: 15px;
}

.headerTitle {
  width: 200px;
  font-weight: 400;
}

.addButton {
  width: 110px;
  height: 30px;
  font-size: 14px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: auto 5px auto auto;
  float: right;
}
