



.title {
  font-size: 20px;
  color: white;
  padding-left: 15px;
  margin-bottom: 10px;
}

.itemContainer {
  background-color: #0E1823;
  padding: 2px 28px;
}


/* Collapsible */

.arrow {
  margin: 8px 26px 0 0;
  height: 17px;
  float: right;
}

.collapsibleItemOpen, .collapsibleItemClosed {
  margin: 25px 0;
  cursor: pointer;
}
.header {
  line-height: 35px;
  font-size: 15.5px;
}

/* Animation */

.collapsibleItemOpen img {
  animation: rotateOpen 300ms linear forwards;
}
.collapsibleItemClosed img {
  animation: rotateClosed 300ms linear forwards;
}
@keyframes rotateOpen {
  from { transform: rotate(180deg); }
  to { transform: rotate(0deg); }
}
@keyframes rotateClosed {
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
}