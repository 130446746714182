

/* Containers */

.pageContainer {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  margin-top: 85px;
  overflow: scroll;
}

.authContainer {
  margin: 100px auto 0px auto;
  width: 80%;
  text-align: center;
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  box-sizing: border-box;
}

/* Eror handling */

.errorContainer {
  margin: 20px auto 0 auto;
  width: 80%;
  text-align: center;
  border: 1px solid #eee;
  padding: 0 5px;
  background-color: #cd4b58;
  color: white;
  font-weight: 600;
  font-size: 15.5px;
  border-radius: 6px;
}

@media (min-width: 600px) {
    .authContainer {
        width: 450px;
    }
    .errorContainer {
        width: 350px;
    }
}


/* Content */

.signInLabel {
  font-weight: 400;
  font-size: 25px;
  margin-top: 20px;
}

.emailField {
  width: 80%;
  margin-top: 15px;
}
.passwordField {
  width: 80%;
  margin-top: 8px;
}

.signInButton {
  background-color: #FF6315;
  color: white;
  font-weight: bold;
  font-size: 14.5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  height: 32px;
  width: 110px;
  display: block;
  margin: 30px auto 20px auto;
}

/* Input */
::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}
::placeholder { /* Recent browsers */
    text-transform: none;
}
input.emailField:focus {
  outline-width: 0;
}
input.passwordField:focus {
  outline-width: 0;
}
input {
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid hsl(0,0%,80%);
  height: 30px;
  margin: 0;
  padding: 0 0 0 5px;
}
