
.contentContainer {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  padding: 20px;
}

.topContainer {
  flex: 1 1 auto;
  width: 50%;
  display: flex;
  flex-flow: column;
}

.bottomContainer {
  display: flex;
  flex-flow: row;

  flex: 1 1 auto;
}


.bottomLeft {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100px;
  display: flex;
  flex-flow: column;
}

.bottomRight {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100px;
  display: flex;
  flex-flow: column;
}
