
.authenticatedContainer {
  height: 65px;
  width: 100%;
  position:fixed;
  top: 0;
  left: 0;
  background-color: #F8F7F8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #E1DFDF;
}
.unauthenticatedContainer {
  height: 65px;
  width: 100%;
  position:fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.leftContainer {
  margin-left: 30px;
  float: left;
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 300px;
  font-size: 30px;
  font-weight: 300;
  float: left;
  line-height: 65px;
  margin: 0 0 0 15px;
}

.logo {
  margin-left: 30px;
  height: 30px;
}

.console {
  margin-top: 34px;
  margin-left: 12px;
  line-height: 65px;
  float: left;
  font-size: 28px;
}

.email {
  margin-right: 30px;
  display: block;
  font: inherit;
  font-size: 15px;
  color: gray;
  float: left;
  margin-top: 5px;
  line-height: 65px !important;
}

.logout {
  float: right;
  margin-right: 30px;
  display: block;
  font: inherit;
  width: 80px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 15px;
  line-height: 65px !important;
}
.logout:focus {
  outline: 0;
}
