
.fullContainer {
  background-color: white;
  flex: 1 1 auto;
  margin: 20px 20px;
  box-shadow: 0px 0px 15px #969696;
}


.titleContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 47px;
  background-color: #F2F3F6;
  margin: 0;
  display: inline-block;
}

.titleContainer p {
  font-size: 18px;
  color: #A79C9C;
  line-height: 47px;
  margin-left: 15px;
  margin-top: 0;
}

.contentContainer {
  padding: 10px;
}
