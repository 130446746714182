
.addButton {
  width: 155px;
  height: 30px;
  font-size: 15px;
  background-color: #FF6315;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 10px 15px auto;
  float: right;
}

.statusIndicator {
  padding: 0 12px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  margin-top: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
