
.rowContainer {
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  flex-flow: row;
  border-top: 1px solid #E5E7ED;
}

.rowContainer.isFirstRow {
  border-top: none;
}

.rowContainer p {
  padding: 0px 5px;
  flex: 1 1 auto;
  line-height: 35px;
}

.title {
  font-weight: 600;
  text-align: left;
  font-size: 15px;
  margin: auto;
}

.dropdown {
  width: 180px;
  font-size: 15px;
  margin: auto;
}

.reducedWidth {
  width: 150px !important;
}
.increasedPadding {
  margin-top: 5px;
  margin-bottom: 5px;
}
.ultrawide {
  width: 56%;
}
